<template>
	<div>
		<div class="header-section">
			<div class="title-section">{{ $t("objectives.objectives") }}</div>
			<div class="action-section">
				<button class="small-link" @click="$router.push({ name: 'user-show', params: { slug: $auth.user().slug }, query: { tab: 'objectives' } })">
					{{ $t("dashboard.see_archive") }}
				</button>
			</div>
		</div>
		<div class="box-table-dashboard" v-if="loaded">
			<template v-if="objectives.length">
				<div class="header">
					<div class="column-filter column-name">
						<div class="text">{{ $t("general.name") }}</div>
					</div>
					<div class="column-filter column-key-results" v-if="$resize && $mq.above(521)">
						<div class="text">{{ $t("key_results.key_results") }}</div>
					</div>
					<div class="column-filter column-deadline" v-if="$resize && $mq.above(521)">
						<div class="text">{{ $t("key_results.deadline") }}</div>
					</div>
					<div class="column-filter column-rating" v-if="$resize && $mq.above(500)">
						<div class="text">{{ $t("key_results.progress") }}</div>
					</div>
				</div>
				<div class="content">
					<div class="table-row" v-for="objective in objectives">
						<!-- Name -->
						<div class="column-name-desc column-name padd-right-30">
							<div class="name">{{ objective.name }}</div>
						</div>
						<!-- Key Results -->
						<div class="column-simple-text column-key-results" v-if="$resize && $mq.above(521)">
							<div class="value">
								<icon-info class="icon orange" v-if="objective.needs_an_update == 'today' || objective.needs_an_update == 'past' || objective.need_evaluate_promise" />
								<div class="text">
									{{ $t("objectives.key-results-progress", { n: objective.key_results_in_progress, total: objective.key_results_count }) }}
								</div>
							</div>
						</div>
						<!-- Deadline -->
						<div class="column-simple-text column-deadline" v-if="$resize && $mq.above(521)">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
								<div class="value" v-if="objective.percent >= 100">
									<icon-info class="icon green" />
									<div class="text">{{ $t("status.completed") }}</div>
								</div>
								<div class="value" v-else-if="diffDays(objective.end_date) < 0">
									<icon-info class="icon red" />
									<div class="text">
										{{ $t("objectives.outdated") }}
									</div>
								</div>
								<div class="value" v-else-if="moment(objective.start_date) >= moment()">
									<div class="text">
										{{ objective.start_date | moment("DD/MM/YYYY") }}
									</div>
								</div>
								<div class="value" v-else>
									<div class="text">
										{{ diffWeeksAndDays(objective.end_date) }}
									</div>
								</div>

								<template slot="popover">
									<div class="simple-text">
										{{ objective.end_date | moment("D MMMM YYYY") }}
									</div>
								</template>
							</v-popover>
						</div>
						<!-- Progres -->
						<div class="column-rating column-value-progress">
							<div class="top-data">
								<div class="current-value">
									<div class="value" v-if="moment(objective.start_date) >= moment()">0</div>
									<div class="value" v-else>{{ parseInt(objective.percent).toString() + "%" }}</div>
								</div>
								<div class="icon-info"><icon-info /></div>
							</div>
							<div class="progress-bar-tbf" v-bind:class="{ disabled: moment(objective.start_date) > moment() }">
								<div
									class="complete-progress-tbf"
									v-bind:class="{ pink: objective.status == 'overdue', green: objective.percent == 100 }"
									:style="'width: calc(2px + ' + (parseInt(objective.percent) < 1 ? '2px' : objective.percent + '%') + ');'"
								></div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="empty-tasks">
					<div class="icon">
						<icon-stars class="stars-empty" />
					</div>
					<div class="text">
						<div class="main">{{ $t("objectives.no_objectives") }}</div>
					</div>
				</div>
			</template>
		</div>
		<loader-evaluation v-else />
	</div>
</template>

<script>
import StarRating from "vue-star-rating";
import IconStars from "../../Icons/Stars";
import IconInfo from "../../Icons/Info";

import LoaderEvaluation from "../../PagesLoaders/DashboardEvaluation";

export default {
	components: {
		StarRating,
		IconStars,
		IconInfo,
		LoaderEvaluation,
	},
	data() {
		return {
			loaded: false,
			objectives: [],
			optionsUnits: {
				"€": "Euro",
				$: "Dolar",
				"%": "Procent",
			},
		};
	},
	mounted() {
		this.getObjectivesEntries();
	},
	methods: {
		getObjectivesEntries() {
			axios
				.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/sync-data/${this.$auth.user().slug}/objectives`, {
					params: {
						status: "active",
					},
				})
				.then(({ data }) => {
					this.objectives = data.data;
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true;
					}, 0);
				});
		},
		diffDays(end_date) {
			var a = moment().startOf("day");
			var b = moment(end_date).startOf("day");
			return b.diff(a, "days");
		},
		diffWeeksAndDays(end_date) {
			var a = moment().startOf("day");
			var b = moment(end_date).startOf("day");

			var diff = moment.duration(b.diff(a));

			return diff.asDays() > 7 ? `${this.$tc("weeks", Math.floor(diff.asWeeks()))} ${diff.days() % 7 != 0 ? this.$tc("days", diff.days() % 7) : ""}` : this.$tc("days", diff.days() % 7);
		},
	},
};
</script>
