<template>
    <div>
        <div class="header-section">
            <div class="title-section">{{ $t('dashboard.reviews') }}</div>
            <div class="action-section">
                <button class="small-link" @click="$router.push({name: 'user-show', params: { slug: $auth.user().slug }, query: {tab: 'congratulations'}})">
                    {{ $t('dashboard.see_all') }}
                </button>
            </div>
        </div>
        <div class="box-table-dashboard" v-if="loaderReviews">
            <template v-if="reviews.length">
                <div class="header">
                    <div class="column-filter column-status">
                        <div class="text"></div>
                    </div>
                    <div class="column-filter column-name">
                        <div class="text">{{ $t('general.message') }}</div>
                    </div>
                    <div class="column-filter column-date" v-if="$resize && $mq.above(521)">
                        <div class="text">{{ $t('general.date') }}</div>
                    </div>
                    <div class="column-filter column-user-avatar">
                    </div>
                </div>
                <div class="content">
                    <div class="table-row" v-for="review in reviews">
                        <div class="column-simple-text column-status">
                            <div class="circle-status" :class="review.type == 'congratulations' ? 'green' : 'red'"></div>
                        </div>
                        <div class="column-name-desc column-name padd-right-30">
                            <div class="name">{{ review.body }}</div>
                            <div class="description">{{ review.role_responsibility.name }}</div>
                        </div>
                        <div class="column-simple-text column-date" v-if="$resize && $mq.above(521)">
                            <div class="text">{{ review.created_at | moment('DD MMM YYYY') }}</div>
                        </div>
                        <div class="column-simple-text padd-right-0 column-user-avatar">
                            <v-popover :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
                                <img class="user-avatar" :src="review.evaluator.avatar" />
                                <template slot="popover">
                                    <div class="simple-text">{{ review.evaluator.first_name + ' ' + review.evaluator.last_name }}</div>
                                </template>
                            </v-popover>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="empty-tasks">
                    <div class="icon">
                        <icon-announcements class="announcements-empty"/>
                    </div>
                    <div class="text">
                        <div class="main">{{ $t('dashboard.no_reviews') }}</div>
                        <!-- <div class="secondary">{{ $t('dashboard.add_new_tasks') }}</div> -->
                    </div>
                </div>
            </template>
        </div>
        <loader-reviews v-else/>
    </div>
</template>

<script>
	import IconAnnouncements from '../../Icons/AnnouncementDuotone'
	import LoaderReviews from '../../PagesLoaders/DashboardReviews'

	export default {
		components: {
            IconAnnouncements,
			LoaderReviews,
		},
		data() {
			return {
				loaderReviews: false,
				reviews: [],
			}
		},
		mounted() {
			this.getUserReviews()
		},
		methods: {
			getUserReviews(){
				axios.get(`/${this.$auth.user().slug}/reviews`, { params: {for_dashboard: true}})
				.then(({data}) => {
					this.reviews = data.data
				}).finally(() => {
					this.loaderReviews = true
				})
			}
		}
	}
</script>