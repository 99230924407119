<template>
	<div>
		<div class="header-section">
			<div class="title-section">{{ $t("dashboard.evaluations") }}</div>
			<div class="action-section">
				<button class="small-link" @click="$router.push({ name: 'user-show', params: { slug: $auth.user().slug }, query: { tab: 'assessments' } })">
					{{ $t("dashboard.see_all") }}
				</button>
			</div>
		</div>
		<div class="box-table-dashboard" v-if="loaderEvaluation">
			<template v-if="evaluations.length">
				<div class="header">
					<div class="column-filter column-name">
						<div class="text">{{ $t("general.message") }}</div>
					</div>
					<div class="column-filter column-rating" v-if="$resize && $mq.above(500)">
						<div class="text">{{ $t("assessments.stars") }}</div>
					</div>
					<div class="column-filter column-user-avatar"></div>
				</div>
				<div class="content">
					<div class="table-row" v-for="evaluation in evaluations">
						<div class="column-name-desc column-name padd-right-30">
							<div class="name">{{ evaluation.good_things }}</div>
							<div class="description">{{ evaluation.what_to_change }}</div>
						</div>
						<div class="column-simple-text column-rating" v-if="$resize && $mq.above(500)">
							<star-rating :rating="evaluation.stars" :show-rating="false" :star-size="15" :increment="0.5" :read-only="true"> </star-rating>
						</div>
						<div class="column-simple-text padd-right-0 column-user-avatar">
							<v-popover :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{ show: 0, hide: 0 }">
								<img class="user-avatar" :src="evaluation.evaluator.avatar" />
								<template slot="popover">
									<div class="simple-text">{{ evaluation.evaluator.first_name + " " + evaluation.evaluator.last_name }}</div>
								</template>
							</v-popover>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="empty-tasks">
					<div class="icon">
						<icon-stars class="stars-empty" />
					</div>
					<div class="text">
						<div class="main">{{ $t("dashboard.no_evaluations_today") }}</div>
						<!-- <div class="secondary">{{ $t('dashboard.add_new_tasks') }}</div> -->
					</div>
				</div>
			</template>
		</div>
		<loader-evaluation v-else />
	</div>
</template>

<script>
import StarRating from "vue-star-rating";
import IconStars from "../../Icons/Stars";

import LoaderEvaluation from "../../PagesLoaders/DashboardEvaluation";

export default {
	components: {
		StarRating,
		IconStars,
		LoaderEvaluation,
	},
	data() {
		return {
			loaderEvaluation: false,
			evaluations: [],
		};
	},
	mounted() {
		this.getUserEvaluations();
	},
	methods: {
		getUserEvaluations() {
			axios
				.get(`/${this.$auth.user().slug}/user-evaluations`, { params: { for_dashboard: true } })
				.then(({ data }) => {
					this.evaluations = data.data;
				})
				.finally(() => {
					this.loaderEvaluation = true;
				});
		},
	},
};
</script>
