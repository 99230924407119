<template>
	<div>
		<div class="header-section">
			<div class="title-section">{{ $t("key_results.key_results") }}</div>
			<div class="action-section">
				<button class="small-link" @click="$router.push({ name: 'user-show', params: { slug: $auth.user().slug }, query: { tab: 'key_results' } })">
					{{ $t("dashboard.see_archive") }}
				</button>
			</div>
		</div>
		<div class="box-table-dashboard" v-if="loaded">
			<template v-if="keyResults.length">
				<div class="header">
					<div class="column-filter column-name">
						<div class="text">{{ $t("general.name") }}</div>
					</div>
					<div class="column-filter column-key-results" v-if="$resize && $mq.above(521)">
						<div class="text">{{ $t("key_results.promises") }}</div>
					</div>
					<div class="column-filter column-key-results" v-if="$resize && $mq.above(521)">
						<div class="text">{{ $t("key_results.updates") }}</div>
					</div>
					<div class="column-filter column-deadline" v-if="$resize && $mq.above(521)">
						<div class="text">{{ $t("key_results.deadline") }}</div>
					</div>
					<div class="column-filter column-last-update" v-if="$resize && $mq.above(521)">
						<div class="text">{{ $t("key_results.last_value") }}</div>
					</div>
					<div class="column-filter column-rating" v-if="$resize && $mq.above(500)">
						<div class="text">{{ $t("key_results.progress") }}</div>
					</div>
				</div>
				<div class="content">
					<div class="table-row" v-for="keyResult in keyResults">
						<!-- Name -->
						<div class="column-name-desc column-name padd-right-30">
							<div class="name">{{ keyResult.name }}</div>
						</div>
						<!-- Promises -->
						<div class="column-simple-text column-key-results" v-if="$resize && $mq.above(521)">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }" :disabled="true">
								<div class="value" v-if="keyResult.need_evaluate_promise">
									<icon-info class="icon orange" />
									<div class="text">
										{{ $t("key_results.status-pending") }}
									</div>
								</div>
								<div class="value" v-else>
									<div class="text">{{ keyResult.promises_completed }}/{{ keyResult.promises_count }} {{ $t("key_results.promise_completed") }}</div>
								</div>

								<template slot="popover">
									<div class="simple-text" v-if="keyResult.status == 'upcoming'">{{ $tc("key_results.tooltip-promises-cant") }}</div>
									<div class="simple-text" v-else>{{ keyResult.need_evaluate_promise ? $t("key_results.tooltip-promises-evaluate") : $t("key_results.tooltip-promises") }}</div>
								</template>
							</v-popover>
						</div>
						<!-- Updates -->
						<div class="column-simple-text column-key-results" v-if="$resize && $mq.above(521)">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }" :disabled="true">
								<div class="value" v-if="keyResult.needs_an_update == 'today' || keyResult.needs_an_update == 'past'">
									<icon-info class="icon orange" />
									<div class="text">
										{{ $t("key_results.status-pending") }}
									</div>
								</div>
								<div class="value" v-else-if="keyResult.last_key_result_log">
									<icon-info class="icon green" />
									<div class="text">
										{{ $tc("key_results.days-ago", diffDaysFrom(keyResult.last_key_result_log.created_at)) }}
									</div>
								</div>
								<div class="value" v-else>
									<div class="text">
										{{ $t("key_results.no-updates") }}
									</div>
								</div>

								<template slot="popover">
									<div class="simple-text" v-if="keyResult.needs_an_update == 'today'">{{ $t("key_results.tooltip-update-today") }}</div>
									<div class="simple-text" v-else-if="keyResult.needs_an_update == 'past'">{{ $t("key_results.tooltip-update-past") }}</div>
									<div class="simple-text" v-else-if="keyResult.needs_an_update">{{ $tc("key_results.tooltip-update-future", keyResult.needs_an_update) }}</div>
									<div class="simple-text" v-else-if="keyResult.status == 'upcoming'">{{ $t("key_results.tooltip-update-cant") }}</div>
									<div class="simple-text" v-else>{{ $t("key_results.tooltip-updates-view") }}</div>
								</template>
							</v-popover>
						</div>
						<!-- Deadline -->
						<div class="column-simple-text column-deadline" v-if="$resize && $mq.above(521)">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
								<div class="value" v-if="keyResult.percent >= 100">
									<icon-info class="icon green" />
									<div class="text">
										{{ $t("key_results.completed") }}
									</div>
								</div>
								<div class="value" v-else-if="diffDays(keyResult.end_date) < 0">
									<icon-info class="icon red" />
									<div class="text">
										{{ $t("objectives.outdated") }}
									</div>
								</div>
								<div class="value" v-else-if="moment(keyResult.start_date) >= moment()">
									<div class="text">
										{{ keyResult.start_date | moment("DD/MM/YYYY") }}
									</div>
								</div>
								<div class="value" v-else>
									<div class="text">{{ diffWeeksAndDays(keyResult.end_date) }}</div>
								</div>
								<template slot="popover">
									<div class="simple-text">
										{{ keyResult.end_date | moment("D MMMM YYYY") }}
									</div>
								</template>
							</v-popover>
						</div>
						<!-- Last value  -->
						<div class="column-simple-text column-last-update" v-if="$resize && $mq.above(521)">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
								<div class="value" v-if="displayKeyResultCurrentValue(keyResult) % 1 != 0">
									<div class="text">
										{{ displayKeyResultCurrentValue(keyResult) | numeral("0.00a") }}{{ optionsUnits[keyResult.unity] ? keyResult.unity : "" }}
										{{ !optionsUnits[keyResult.unity] ? keyResult.unity : optionsUnits[keyResult.unity] }}
									</div>
								</div>
								<div class="value" v-else>
									<div class="text">
										{{ displayKeyResultCurrentValue(keyResult) | numeral("0a") }}{{ optionsUnits[keyResult.unity] ? keyResult.unity : "" }}
										{{ !optionsUnits[keyResult.unity] ? keyResult.unity : "(" + optionsUnits[keyResult.unity] + ")" }}
									</div>
								</div>
								<template slot="popover">
									<div class="simple-text">
										{{
											$t("show_user.key_result_progress_type." + keyResult.progress_type, {
												to: keyResult.target % 1 != 0 ? $options.filters.numeral(keyResult.target, "0,0.00") : $options.filters.numeral(keyResult.target, "0,0"),
												unit: keyResult.unity,
												from:
													keyResult.start_value % 1 != 0 ? $options.filters.numeral(keyResult.start_value, "0,0.00") : $options.filters.numeral(keyResult.start_value, "0,0"),
											})
										}}
									</div>
								</template>
							</v-popover>
						</div>
						<!-- Progress -->
						<div class="column-rating column-value-progress">
							<v-popover offset="10" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
								<div class="top-data">
									<div class="current-value">
										<div class="value" v-if="moment(keyResult.start_date) >= moment()">0</div>
										<div class="value" v-else>{{ parseInt(keyResult.percent).toString() + "%" }}</div>
									</div>
									<div class="icon-info"><icon-info /></div>
								</div>
								<div class="progress-bar-tbf" v-bind:class="{ disabled: moment(keyResult.start_date) > moment() }">
									<div
										class="complete-progress-tbf"
										v-bind:class="{ pink: keyResult.status == 'overdue', green: keyResult.percent == 100 }"
										:style="'width: calc(2px + ' + (parseInt(keyResult.percent) < 1 ? '2px' : keyResult.percent + '%') + ');'"
									></div>
								</div>

								<template slot="popover">
									<div class="simple-text">
										<span v-if="keyResult.target % 1 != 0 || keyResult.start_value % 1 != 0"
											>{{
												$t("show_user.key_result_progress_type." + keyResult.progress_type, {
													to: $options.filters.numeral(keyResult.target, "0,0.00"),
													unit: keyResult.unity,
													from: $options.filters.numeral(keyResult.start_value, "0,0.00"),
												})
											}}.
										</span>
										<span v-else
											>{{
												$t("show_user.key_result_progress_type." + keyResult.progress_type, {
													to: $options.filters.numeral(keyResult.target, "0,0"),
													unit: keyResult.unity,
													from: $options.filters.numeral(keyResult.start_value, "0,0"),
												})
											}}.
										</span>

										<span v-if="displayKeyResultCurrentValue(keyResult) % 1 != 0"
											>{{ $t("show_user.key_result_progress_type.current_value") }}: {{ displayKeyResultCurrentValue(keyResult) | numeral("0,0.00") }} {{ keyResult.unity }}</span
										>
										<span v-else
											>{{ $t("show_user.key_result_progress_type.current_value") }}: {{ displayKeyResultCurrentValue(keyResult) | numeral("0,0") }} {{ keyResult.unity }}</span
										>
									</div>
								</template>
							</v-popover>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="empty-tasks">
					<div class="icon">
						<icon-stars class="stars-empty" />
					</div>
					<div class="text">
						<div class="main">{{ $t("key_results.no_key_results") }}</div>
					</div>
				</div>
			</template>
		</div>
		<loader-evaluation v-else />
	</div>
</template>

<script>
import StarRating from "vue-star-rating";
import IconStars from "../../Icons/Stars";
import IconInfo from "../../Icons/Info";

import LoaderEvaluation from "../../PagesLoaders/DashboardEvaluation";

export default {
	components: {
		StarRating,
		IconStars,
		IconInfo,
		LoaderEvaluation,
	},
	data() {
		return {
			loaded: false,
			keyResults: [],
			optionsUnits: {
				"€": "Euro",
				$: "Dolar",
				"%": "Procent",
			},
		};
	},
	mounted() {
		this.getKeyResults();
	},
	methods: {
		getKeyResults() {
			axios
				.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/sync-data/${this.$auth.user().slug}/key-results`, { params: { status: "active" } })
				.then(({ data }) => {
					this.keyResults = data.data;
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true;
					}, 0);
				});
		},
		displayKeyResultCurrentValue(keyResult) {
			var text = "";
			var value = keyResult.last_update_value ? keyResult.last_update_value : keyResult.start_value;
			return value.includes(".00") ? parseInt(value).toString() : parseFloat(value).toString();
		},
		diffDays(end_date) {
			var a = moment().startOf("day");
			var b = moment(end_date).startOf("day");
			return b.diff(a, "days");
		},
		diffDaysFrom(update_date) {
			var a = moment(update_date).startOf("day");
			var b = moment().startOf("day");
			return b.diff(a, "days");
		},
		diffWeeksAndDays(end_date) {
			var a = moment().startOf("day");
			var b = moment(end_date).startOf("day");

			var diff = moment.duration(b.diff(a));

			return diff.asDays() > 7 ? `${this.$tc("weeks", Math.floor(diff.asWeeks()))} ${diff.days() % 7 != 0 ? this.$tc("days", diff.days() % 7) : ""}` : this.$tc("days", diff.days() % 7);
		},
	},
};
</script>
